h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: Verdana;
}
#dropdown-basic-button {
  padding: 5px 0px;
}